<template>
  <div class="take-photo-wrap">
    <el-dialog
      title="人脸识别"
      :visible.sync="inVisible"
      width="600px"
      @opened="opened"
      :before-close="onCloseDialogEvent"
    >
      <!--内容-->
      <div class="content-wrap">
        <!--拍照区域-->
        <div class="camera-zone-wrap">
          <div id="camera-upload-box" class="camera-zone"></div>
          <div id="camera-photo" class="camera-photo" :style="isReTake ? {display: 'block'} : {display: 'none'}"></div>
        </div>

        <!--按钮数组-->
        <div class="right-button-group">
          <div class="button-wrap">
            <el-button v-if="isReTake" type="primary" @click="onClickReTakeEvent">重新拍照</el-button>
            <el-button v-if="!isReTake" type="primary" @click="onClickTakePhotoEvent">立即拍照</el-button>
          </div>
        </div>
      </div>

      <!--按钮组-->
      <div class="button-group">
        <el-button class="cancle-button" @click="onCloseDialogEvent">取消</el-button>
        <el-button class="ensure-button" @click="onClickEnsureEvent">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
/*
 *  拍照弹框
 **/
import CameraUtil from "@/util/CameraUtil";
import { HudGlobal } from "@/util/HudGlobal";
import DateUtil from "@/util/DateUtil";

export default {
  name: "TakePhotoDialog.vue",
  props: {
    /**
     * 是否显示 默认 false
     */
    /*isVisible: {
                type: Boolean,
                default: false,
            },*/
    fileName: {
      type: String
    },
    // 关闭窗口时, 不关闭摄像头, 会影响其它页面
    closeCamera: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isCloseCamera: true,
      inVisible: false,
      photoObject: null,
      isReTake: false //是否重新拍照
    };
  },
  created() {
    this.isCloseCamera = this.closeCamera;
    this.isReTake = false;
  },
  mounted() {},
  updated() {
    // if (!this.isReTake) {
    //     // 必须在挂载的时候使用
    //     this.setupInitCamera();
    // }
  },
  methods: {
    opened() {
      if (!this.isReTake) {
        // 必须在挂载的时候使用
        this.setupInitCamera();
      }
    },
    /**
     *
     *  点击拍照
     * */
    onClickTakePhotoEvent() {
      const that = this;
      let dateFtt = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());

      const fileName = this.fileName || dateFtt;
      CameraUtil.takeCameraSnapWithFileName(fileName).then(response => {
        that.photoObject = response;
          that.isReTake = true;
          //显示照片
          let cameraUploadBox = document.getElementById('camera-photo');
          cameraUploadBox.innerHTML = '';
          cameraUploadBox.innerHTML = '<img src="' + response.base64 + '"/>';


      });
    },
    show() {
      this.inVisible = true;
    },
    hide() {
      this.inVisible = false;
    },
    /**
     *  重新拍照
     * */
    onClickReTakeEvent() {
        this.resetReTakeData();
        this.setupInitCamera();

    },
    /**
     *  复位照相机
     * */
    resetReTakeData() {
      document.getElementById("camera-upload-box").innerHTML = "";
      this.isReTake = false;
    },

    /**
     * 关闭弹框
     */
    onCloseDialogEvent() {
      if (this.isCloseCamera) {
        CameraUtil.closeCamera();
        this.$emit("closeDialogCamera");
      }

      this.hide();
      this.$emit("call-back", { type: 0, data: null });
      this.resetReTakeData();
    },

    /**
     *  点击确定按钮
     */
    onClickEnsureEvent() {
      if (this.photoObject == null) {
        HudGlobal.showWarningWithMessage("请完成拍照");
        return;
      }
      this.onCloseDialogEvent();
      this.$emit("call-back", { type: 1, data: this.photoObject });
      this.resetReTakeData();
    },
    /*
     *  初始化相机
     * */
    setupInitCamera() {
      const width = 240;
      const height = 260;
      CameraUtil.resetCamera();
      CameraUtil.initWithCamera("camera-upload-box", width, height);
      this.photoObject = null;
    }
  },
  watch: {
    // isVisible: {
    //     immediate: true,
    //     handler(val) {
    //         this.inVisible = val;
    //     }
    // }
  }
};
</script>

<style lang="scss">
.take-photo-wrap {
  // 头部
  .el-dialog__header {
    box-sizing: border-box;
    text-align: left;
    border-bottom: 1px solid #e6e6e6;
    margin-left: 20px;
    margin-right: 20px;
    padding: 12px 0px;
    color: #333333;
    font-size: 16px;
    // 关闭按钮
    .el-dialog__headerbtn {
      top: 16px;
    }
  }
  // 内容
  .el-dialog__body {
    padding: 40px 50px 30px 50px;
    .content-wrap {
      display: flex;
      // 拍照区域
      .camera-zone-wrap{
        position: relative;
         //width: 220px;
        //height: 280px; 
        // 拍照区域
        .camera-zone {
          position: absolute;
          background: #000000;
          border: 1px solid #E6E6E6;
          border-radius: 4px;
          overflow: hidden;
          video {
            position: absolute;
            top:0;
          }
        }
        .camera-photo{
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom:0px;
          //background: #000000;
          //border: 1px solid #E6E6E6;
          border-radius: 4px;
          overflow: hidden;
          width: 100%;
          height: 100%;
        }

      }

      //右侧的按钮数据
      .right-button-group {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .el-button {
          margin-left: 0px;
          margin-top: 10px;
        }
      }
    }
    .button-group {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      font-size: 14px;
      font-family: MicrosoftYaHei;

      .el-button {
        width: 120px;
        height: 36px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        box-sizing: border-box;
      }

      //取消按钮
      .cancle-button {
        background: #e6e6e6;
        color: #333333;
      }
      //确定按钮
      .ensure-button {
        background: #409eff;
        color: white;
        margin-left: 20px;
      }
    }
  }
}
</style>
