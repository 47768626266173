import http from "../../util/http/baseHttp"
const baseUrl = process.env.VUE_APP_API_BASE_URL;

/**
 * 人像管理类, 和userInfoApi.js有一定的重叠
 */
export default {

    //　修改人像
    faceMod: function (params) {
        return http.put(baseUrl + "/student/user/face/mod", params);
    },
}
