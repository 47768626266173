import Uploader from "@tibi/tb-upload-plugin";
import { HudGlobal } from './HudGlobal.js'
import studentHttp from "./http/studentHttp.js";
import DateUtil from "./DateUtil.js";


// 基础的URL
const BASE_SERVE_API_URL = process.env.VUE_APP_API_SERVICE_URL;
const BASE_API_URL = process.env.VUE_APP_API_BASE_URL;
// 基础路径
const BASE_TOKEN_PATH = "/api/oss/token";


//=====================================可配置上传文件夹路径================================//

// 投诉建议文件路径
const STUDENT_SUGGESTION_PHOTO = "/student/image/suggestPhoto/";

// 注册照路径
const REGISTER_PHOTO = "/user/reg/";


//=====================================================================================//

export default {

    /*
     *
     *  投诉建议 -上传图片 - 未使用
     * */
    uploadSuggestionPhoto: function (file, storePath) {
        return this.baseUploadPhoto(file, storePath || STUDENT_SUGGESTION_PHOTO);

    },
    /**
     *  注册照图片 - 上传图片
     */
    uploadRegisterPhoto: function (file, storePath) {
        const fileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const dirPath = storePath || REGISTER_PHOTO + fileName + "/";
        return this.baseUploadPhoto(file, dirPath);
    },
    /**
     *  注册照图片 - 上传图片
     */
    uploadVerifyPhoto: function (file, storePath) {
        const fileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const dirPath = storePath || REGISTER_PHOTO + fileName + "/";

        return this.baseUploadPhoto(file, dirPath);
    },

    /*
    *  基础上传
    * */
    baseUploadPhoto(file, path) {
        return new Promise((resolve, reject) => {
            const url = BASE_SERVE_API_URL + BASE_TOKEN_PATH;

            const opts = {
                hwConfig: {},
                aliConfig: {},
                tbConfig: {}
            };
            opts.tokenRequestUrl = url;
            opts.platform = window.$TBUpload.uploadPlatform;
            opts.videoAVCRequestUrl = `${BASE_SERVE_API_URL}/api/video/toH264`;

            // 阿里云配置
            opts.aliConfig.region = process.env.VUE_APP_ALIOSS_REGION;
            opts.aliConfig.bucket = process.env.VUE_APP_ALIOSS_BUCKET;
            // 替比云配置
            opts.tbConfig.platform = window.$TBUpload.uploadSubPlatform;
            opts.tbConfig.region = process.env.VUE_APP_ALIOSS_REGION;
            opts.tbConfig.bucket = opts.tbConfig.platform === "ALI" ? process.env.VUE_APP_ALIOSS_BUCKET : process.env.VUE_APP_HUAWEI_BUCKET;
            opts.tbConfig.uploadURL = `${BASE_SERVE_API_URL}/api/oss/upload/common`;

            // 华为配置
            opts.hwConfig.bucket = process.env.VUE_APP_HUAWEI_BUCKET;

            const uploader = new Uploader(opts);

            uploader
                .uploaderFile(file, path)
                .then(res => {
                    console.log("上传结果====", res);
                    if (res.success) {
                        resolve && resolve(res);
                    } else {
                        HudGlobal.showErrorWithMessage(res.description || "上传失败");
                        resolve && resolve(res);
                    }
                })
                .catch(error => {
                    console.log("上传失败======", error);
                    HudGlobal.showErrorWithMessage("上传失败");
                    resolve && resolve(null);
                });
        });
    },
    /**
     *  获取平台配置
     * @returns
     */
    getUploadPlatformConfig(orgId) {
        return new Promise((resolve, reject) => {
            const param = { parameterCode: "upload_oss_type", orgId };
            studentHttp.get(BASE_API_URL + "/system/parameter/code/list", param)
                .then((res) => {
                    if (res && res.success) {
                        resolve && resolve(res.data.content);
                    } else {
                        resolve && resolve(null);
                    }
                })
                .catch((err) => {
                    resolve && resolve(null);
                });
        });
    },
}
