var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "take-photo-wrap" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人脸识别",
            visible: _vm.inVisible,
            width: "600px",
            "before-close": _vm.onCloseDialogEvent
          },
          on: {
            "update:visible": function($event) {
              _vm.inVisible = $event
            },
            opened: _vm.opened
          }
        },
        [
          _c("div", { staticClass: "content-wrap" }, [
            _c("div", { staticClass: "camera-zone-wrap" }, [
              _c("div", {
                staticClass: "camera-zone",
                attrs: { id: "camera-upload-box" }
              }),
              _c("div", {
                staticClass: "camera-photo",
                style: _vm.isReTake
                  ? { display: "block" }
                  : { display: "none" },
                attrs: { id: "camera-photo" }
              })
            ]),
            _c("div", { staticClass: "right-button-group" }, [
              _c(
                "div",
                { staticClass: "button-wrap" },
                [
                  _vm.isReTake
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onClickReTakeEvent }
                        },
                        [_vm._v("重新拍照")]
                      )
                    : _vm._e(),
                  !_vm.isReTake
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onClickTakePhotoEvent }
                        },
                        [_vm._v("立即拍照")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "button-group" },
            [
              _c(
                "el-button",
                {
                  staticClass: "cancle-button",
                  on: { click: _vm.onCloseDialogEvent }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "ensure-button",
                  on: { click: _vm.onClickEnsureEvent }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }