/*
 * @Author: fbz
 * @Date: 2021-08-25 14:38:48
 * @LastEditors: fbz
 * @LastEditTime: 2021-10-14 10:40:00
 */
import planApi from "../../../../api/planApi.js";
import StoreDBManger from "../../../../util/StoreDBManger.js";
import { gotoHomeList } from "../../../middle-ware/utils/ControlRouter.js";

export default {
    name: "LoginOutDialog",
    props: {
        propDialogVisible: Boolean,
        propDialogObject: Object,
    },
    data() {
        const validateRequirePasswd = (rule, value, callback) => {
            const rules = /^(\w){6,18}$/;
            if (!rules.test(value)) {
                callback(new Error("请输入6-18位的正确密码"));
            } else if (this.passWordError) {
                callback(new Error("密码错误,请重新输入"));
            }
            callback();
        };
        return {
            ruleForm: {
                passWord: "", // 密码
            },
            dialogVisible: false, // 弹窗是否显示
            rules: {
                passWord: [{ required: true, trigger: "change", validator: validateRequirePasswd }],
            },
            meg: "",
            passWordError: false,
        };
    },
    watch: {
        propDialogVisible: {
            immediate: true,
            handler(value) {
                this.dialogVisible = value;
            },
        },
        propDialogObject: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.meg = value.msg;
                }
            },
            deep: true,
        },
    },
    methods: {
        /**
         * 更改内容的时候，标识置为false
         */
        changePwd() {
            if (this.passWordError) {
                this.passWordError = false;
            }
        },
        /**
         * 关闭弹窗
         */
        closeDialog() {
            this.$emit("update:propDialogVisible");
            this.$refs.ruleForm.resetFields();
        },
        /**
         * 取消弹窗
         */
        handleClose() {
            // 0或空为签到, 1抽验, 2签退
            StoreDBManger.saveDataToLocalStorage(StoreDBManger.storageKeys.SELECT_SIGN_TYPE, 2);
            location.reload();
        },
        /**
         * 确认退出
         */
        loginOut() {
            const that = this;
            this.$refs.ruleForm.validate((valid, object) => {
                if (valid) {
                    that.passwdVerity();
                }
            });
        },
        passwdVerity() {
            const that = this;
            const params = {
                userId: that.$store.getters.userId,
                passwd: that.ruleForm.passWord,
            };
            planApi.passwdVerity(params).then((response) => {
                const { success, code } = response;
                if (success) {
                    this.$refs.ruleForm.validateField("passWord");
                    this.passWordError = true;
                    gotoHomeList({});
                    this.closeDialog();
                } else if (code * 1 === 1) {
                    that.passWordError = true;
                    that.$refs.ruleForm.validateField("passWord");
                }
                that.loading = false;
            });
        },
    },
};
