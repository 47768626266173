var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container signin" },
    [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "train-paln-info" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.planData.planName || "计划名称错误"))
          ]),
          _c("div", { staticClass: "info-wrap" }, [
            _c("ul", { staticClass: "plan-list" }, [
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.isOfflineMeeting ? "会议周期" : "计划周期"))
                ]),
                _vm._v(
                  "：" +
                    _vm._s(
                      _vm._f("timeFormatFilter")(
                        _vm.planData.planDto
                          ? _vm.planData.planDto.startTime
                          : "时间读取错误"
                      )
                    ) +
                    " 至 " +
                    _vm._s(
                      _vm._f("timeFormatFilter")(
                        _vm.planData.planDto
                          ? _vm.planData.planDto.endTime
                          : "时间读取错误"
                      )
                    ) +
                    " "
                )
              ]),
              _c("li", [
                _vm._v(
                  "参加人员：" +
                    _vm._s(
                      _vm.planData.planDto ? _vm.planData.planDto.userCount : 0
                    ) +
                    "人"
                )
              ]),
              _c("li", [
                _vm._v(
                  " 培训进度：已完成" +
                    _vm._s(
                      _vm.planData.planDto
                        ? _vm.planData.planDto.studyFinishCount || 0
                        : 0
                    ) +
                    "人， 剩余" +
                    _vm._s(
                      _vm.planData.planDto
                        ? _vm.planData.planDto.restCount || 0
                        : 0
                    ) +
                    "人 "
                )
              ]),
              _c("li", [
                _vm._v(
                  "所属组织：" +
                    _vm._s(
                      _vm.planData.planDto
                        ? _vm.planData.planDto.orgName
                        : "组织读取错误"
                    )
                )
              ])
            ]),
            _c("i", { staticClass: "line" }),
            _c(
              "ul",
              { staticClass: "manager-role" },
              [
                _vm._l(_vm.planData.offlinePlanLeaderDtoList, function(
                  item,
                  index
                ) {
                  return _c("li", { key: index }, [
                    _c("span", [
                      _c("label", [_vm._v(_vm._s(item.leaderName) + "：")]),
                      _vm._v(
                        " " +
                          _vm._s(item.realName || `无${item.leaderName}`) +
                          " "
                      )
                    ])
                  ])
                }),
                _c("li", [
                  _c("span", [
                    _c("label", [_vm._v("培训教室：")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.planData.classroomName || "无可用教室") +
                        " "
                    )
                  ])
                ]),
                _vm.isOfflineMeeting
                  ? _c("li", { staticClass: "meeting-info-cell" }, [
                      _c(
                        "span",
                        [
                          _c("label", [_vm._v("会议简介：")]),
                          _vm.planData.planDto &&
                          _vm.planData.planDto.introduction &&
                          _vm.planData.planDto.introduction.length > 10
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.planData.planDto.introduction,
                                    "popper-class": "common-tip-popper"
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.planData.planDto &&
                                            _vm.planData.planDto
                                              .introduction) ||
                                            "暂无会议简介"
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (_vm.planData.planDto &&
                                        _vm.planData.planDto.introduction) ||
                                        "暂无会议简介"
                                    )
                                )
                              ])
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "sign-tips-wrapper" },
          [
            _vm.notice
              ? [
                  _c("span", [_vm._v("温馨提示: ")]),
                  _c("div", { ref: "notice-box", staticClass: "notice-box" }, [
                    _c(
                      "div",
                      {
                        ref: "notice-bar",
                        staticClass: "white-nowrap",
                        class: { "notice-bar": _vm.isMove }
                      },
                      [_vm._v(_vm._s(_vm.notice))]
                    )
                  ])
                ]
              : _vm._e()
          ],
          2
        ),
        _c("canvas", {
          staticStyle: { display: "none" },
          attrs: { id: "qr-code-image-box" }
        }),
        _c("div", { staticClass: "sign-list-wrap" }, [
          _c(
            "div",
            { staticClass: "sign-list" },
            [
              _c("div", { attrs: { id: _vm.operationGuideListId[0] } }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "待" +
                      _vm._s(_vm.signType.signTypeName) +
                      "人员(" +
                      _vm._s(_vm.getLeftSignCount) +
                      ")"
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "search-input" },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        size: "small",
                        clearable: "",
                        placeholder: "请输入姓名/身份证号"
                      },
                      model: {
                        value: _vm.studentListParams.searchVal,
                        callback: function($$v) {
                          _vm.$set(_vm.studentListParams, "searchVal", $$v)
                        },
                        expression: "studentListParams.searchVal"
                      }
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: { loading: _vm.loading },
                        on: { click: _vm.studentSearch }
                      },
                      [_vm._v("搜索 ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.tableData }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "姓名", width: "120" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "span",
                                    { attrs: { title: scope.row.cardNo } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.realName) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "identityName", label: "身份" }
                        }),
                        _c("el-table-column", {
                          attrs: { fixed: "right", label: "操作", width: "70" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "table-btn-sign-in-text",
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.signInStart(
                                            scope.row,
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.signUserInfo.signInTableText
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("el-pagination", {
                attrs: {
                  layout: "prev, pager, next",
                  "hide-on-single-page": false,
                  "pager-count": _vm.studentListParams.pagerCount,
                  "current-page": _vm.studentListParams.currentPage,
                  "page-size": _vm.studentListParams.pageSize,
                  total: _vm.tableDataTotal
                },
                on: { "current-change": _vm.currentPageChange }
              })
            ],
            1
          ),
          _c("div", { staticClass: "verify" }, [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c("span", [
                  _vm._v("验证区(" + _vm._s(_vm.signType.signTypeName) + ")")
                ]),
                _c(
                  "el-link",
                  {
                    staticClass: "update-user-face",
                    attrs: { type: "info" },
                    on: { click: _vm.updateUserFace }
                  },
                  [_vm._v("修改注册照 ")]
                )
              ],
              1
            ),
            _vm.signCtrlParam.isFaceVerify
              ? _c(
                  "div",
                  { staticClass: "btns" },
                  [
                    _vm.signCtrlParam.faceLiveDetectionApp == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleSignInType("phone")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.signInType.phoneSignInName) + " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.signCtrlParam.faceLiveDetectionPc == 1 &&
                    !_vm.isOfflineMeeting
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleSignInType("pc")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.signInType.pcSignInName) + " "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.startToClazz
              ? _c(
                  "div",
                  { staticClass: "start-to-clazz" },
                  [
                    _vm.isOfflineMeeting
                      ? _c("span", [
                          _vm._v(
                            " [提示:" +
                              _vm._s(_vm.planData.safeAdmin.realName) +
                              "]正在会议验证, 验证通过后自动开始会议 "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            " [提示:" +
                              _vm._s(_vm.planData.safeAdmin.realName) +
                              "]正在上课验证, 验证通过后自动开始上课 "
                          )
                        ]),
                    _c(
                      "el-link",
                      {
                        staticClass: "to-clazz-close fr",
                        attrs: { type: "warning" },
                        on: { click: _vm.toClazzClose }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.isOfflineMeeting
                              ? "退出会议验证"
                              : "退出上课验证"
                          ) + " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.signInType.pcSignIn === 1 && _vm.signInType.phoneSignIn === 1
              ? _c("div", { staticClass: "no-sign-in-type" }, [
                  _vm._v("请开启" + _vm._s(_vm.signType.signTypeName) + "方式")
                ])
              : _vm._e(),
            _vm.signInType.pcSignIn === 0
              ? _c(
                  "div",
                  {
                    staticClass: "scanning",
                    attrs: { id: _vm.operationGuideListId[2] }
                  },
                  [
                    _c("div", { staticClass: "register-photo" }, [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.signUserInfo.srcUserInfo.faceUrl ||
                            _vm.signUserInfo.defaultHead,
                          alt: ""
                        }
                      }),
                      _c("p", [_vm._v("注册照")])
                    ]),
                    _c("div", { staticClass: "verify-tip-box" }, [
                      !_vm.signUserInfo.srcUserInfo
                        ? _c("div", { staticClass: "tip-text" }, [
                            _vm._v(
                              " 点击左侧列表中“" +
                                _vm._s(_vm.signType.signTypeName) +
                                "“按钮 或 手机登录安途帮扫码" +
                                _vm._s(_vm.signType.signTypeName) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.signUserInfo.srcUserInfo
                        ? _c(
                            "div",
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.signUserInfo.srcUserInfo.realName)
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "verify-identity-text",
                                  attrs: {
                                    title:
                                      _vm.signUserInfo.srcUserInfo
                                        .identityNameStr
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.signUserInfo.srcUserInfo
                                          .identityName ||
                                          _vm.signUserInfo.srcUserInfo
                                            .identityNameStr ||
                                          "学员"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "verify-state-text",
                                  attrs: {
                                    title: _vm.signUserInfo.signInStatus
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.signUserInfo.signInStatus) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    loading: _vm.takePhotoLoading
                                  },
                                  on: { click: _vm.takeSignInPhoto }
                                },
                                [_vm._v("拍照验证 ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _vm._m(0)
                  ]
                )
              : _vm._e(),
            _vm.signInType.pcSignIn === 1 && _vm.signInType.phoneSignIn === 0
              ? _c(
                  "div",
                  { staticClass: "qrcode" },
                  [
                    _c("QrCodeLogo", {
                      ref: "qrCodeLogoBox1",
                      attrs: {
                        content: _vm.qrCodeContent,
                        logoUrl: _vm.logoImg,
                        opts: _vm.opts
                      }
                    }),
                    _c("p", [
                      _vm._v(_vm._s(_vm.signType.signTypeName) + "二维码")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm.signCtrlParam.isFaceVerify
              ? _c("div", { staticClass: "scanning-tips" }, [
                  _c(
                    "div",
                    { staticClass: "photo" },
                    [
                      _c("h4", [_vm._v("图像示例")]),
                      _vm._m(1),
                      _vm._m(2),
                      _vm.wechatQrCode
                        ? [
                            _c("div", [
                              _c("img", {
                                staticClass: "wechat-qrCode",
                                attrs: { src: _vm.wechatQrCode }
                              })
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ])
              : _c("div", { staticClass: "no-sign-in-face" }, [
                  _vm._v(
                    "系统设置您不需要人脸识别签到, 点击左侧签到或使用手机扫码即可完成签到"
                  )
                ])
          ])
        ])
      ]),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "study-button-wrap" }, [
          _vm.signType.signType === 0
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.nextLoading,
                      expression: "nextLoading"
                    }
                  ],
                  staticClass: "study-button-container",
                  attrs: { id: "step210" },
                  on: { click: _vm.toTrain }
                },
                [
                  _c("img", {
                    staticClass: "button-icon",
                    attrs: {
                      src: require("../../assets/img/sigin_in_play_icon.png")
                    }
                  }),
                  _c("span", { staticClass: "button-title" }, [
                    _vm._v(
                      _vm._s(_vm.isOfflineMeeting ? "开始会议" : "开始上课")
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm.signType.signType === 1 || _vm.signType.signType === 2
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.nextLoading,
                      expression: "nextLoading"
                    }
                  ],
                  staticClass: "study-button-container",
                  attrs: { id: "step220" },
                  on: { click: _vm.toContinueTrain }
                },
                [
                  _c("img", {
                    staticClass: "button-icon",
                    attrs: {
                      src: require("../../assets/img/sigin_in_play_icon.png")
                    }
                  }),
                  _c("span", { staticClass: "button-title" }, [
                    _vm._v(
                      _vm._s(_vm.isOfflineMeeting ? "继续会议" : "继续上课") +
                        " "
                    )
                  ])
                ]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "sign-recorde-wrap" }, [
          _vm.signInType.pcSignIn === 0 && _vm.signInType.phoneSignIn === 0
            ? _c(
                "div",
                {
                  staticClass: "qrcode",
                  attrs: { id: _vm.operationGuideListId[1] }
                },
                [
                  _c("QrCodeLogo", {
                    ref: "qrCodeLogoBox",
                    attrs: {
                      content: _vm.qrCodeContent,
                      logoUrl: _vm.logoImg,
                      opts: _vm.opts
                    }
                  }),
                  _c("p", [
                    _vm._v(_vm._s(_vm.signType.signTypeName) + "二维码")
                  ]),
                  _c(
                    "el-button",
                    {
                      staticClass: "close-button",
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.handleSignInType("phone")
                        }
                      }
                    },
                    [_vm._v(" 关闭 ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("div", { attrs: { id: _vm.operationGuideListId[3] } }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.signType.signTypeName) + "记录")
            ]),
            _vm.signInLogList.list.length === 0
              ? _c("div", { staticClass: "sign-in-box-no-data" }, [
                  _vm._v("暂时还没有人" + _vm._s(_vm.signType.signTypeName))
                ])
              : _c(
                  "div",
                  { staticClass: "sign-in-box" },
                  [
                    _c("p", [
                      _vm._v(
                        "已" +
                          _vm._s(_vm.signType.signTypeName) +
                          " " +
                          _vm._s(_vm.getRightSignCount) +
                          "人"
                      )
                    ]),
                    _c(
                      "ul",
                      { staticClass: "record-list" },
                      _vm._l(_vm.signInLogList.list, function(item, index) {
                        return _c("li", { key: index }, [
                          _c("div", { staticClass: "record" }, [
                            _c(
                              "span",
                              {
                                staticClass: "name",
                                attrs: { title: item.cardNo }
                              },
                              [_vm._v(" " + _vm._s(item.realName) + " ")]
                            ),
                            _c("span", { staticClass: "role" }, [
                              _vm._v(_vm._s(item.identityName))
                            ]),
                            _c("span", { staticClass: "sign-way" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("signSourceFilter")(item.signSource)
                                  ) +
                                  _vm._s(
                                    _vm._f("resultTypeFilter")(item.resultType)
                                  ) +
                                  _vm._s(_vm.signType.signTypeName) +
                                  " "
                              )
                            ])
                          ]),
                          _vm.signType.signType === 2
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("timestampFilter")(item.endTime)
                                    ) +
                                    " "
                                )
                              ])
                            : _vm.signType.signType === 1
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("timestampFilter")(
                                        item.validateTime
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("timestampFilter")(item.startTime)
                                  )
                                )
                              ])
                        ])
                      }),
                      0
                    ),
                    _c("el-pagination", {
                      attrs: {
                        layout: "prev, pager, next",
                        "hide-on-single-page": false,
                        "pager-count": _vm.signInLogList.pagerCount,
                        "current-page": _vm.signInLogList.currentPage,
                        "page-size": _vm.signInLogList.pageSize,
                        total: _vm.signInLogList.signInNumber
                      },
                      on: { "current-change": _vm.currentSignDoPageChange }
                    })
                  ],
                  1
                )
          ])
        ])
      ]),
      _c("TakePhotoDialog", {
        ref: "takePhotoDialog",
        on: {
          "call-back": _vm.hanldeTakePhotoCallBackEvent,
          closeDialogCamera: _vm.closeDialogCamera
        }
      }),
      _c("LoginOutDialog", {
        attrs: {
          propDialogVisible: _vm.logOutDialog.logOutDialogVisible,
          propDialogObject: _vm.logOutDialog
        },
        on: {
          "update:propDialogVisible": function($event) {
            return _vm.$set(_vm.logOutDialog, "logOutDialogVisible", $event)
          },
          "update:prop-dialog-visible": function($event) {
            return _vm.$set(_vm.logOutDialog, "logOutDialogVisible", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "scanning-photo" }, [
      _c("div", { attrs: { id: "signInUserBox" } }),
      _c("p", [_vm._v("摄像框")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("img", {
        attrs: { src: require("@/assets/img/sign-info.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "tips-list" }, [
      _c("ul", [
        _c("li", [_vm._v("1. 保持摄像头正对自己，距离适 中；")]),
        _c("li", [_vm._v("2. 请调整坐姿，使头像位于人脸 识别区域内")]),
        _c("li", [_vm._v("3. 调节亮度和摄像头焦距使人脸显 示清晰；")]),
        _c("li", [_vm._v("4. 环境光线尽可能的均匀，无曝 光、欠曝光；")]),
        _c("li", [_vm._v("5. 图像中人的眼镜不反光，并保 证双眼可见；")]),
        _c("li", [_vm._v("6. 无墨镜、口罩等物体遮挡面部；")]),
        _c("li", [_vm._v("7. 保证人脸、俯仰角度在+15~ -15度之间；")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }