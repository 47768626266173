import WebCamJS from  "@tibi/webcam";
import {HudGlobal} from "./HudGlobal";

/*
 *  相机处理工具类
 **/
export default {

    // 配置中心
    config: {
        width: 220,
        height: 280,
        crop_width: 210,
        crop_height: 340,
        image_format: "png",
        jpeg_quality: 100,
        force_flash: false,
        flip_horiz: true, //不裁剪 不会旋转
        enable_image_flip_horiz: true,
        fps: 60,
        swfURL: window.location.protocol + "//" + window.location.host + "/webcam.swf",
    },
    // 摄像头会话
    mediaStreamTrack: null,
    elementId: null,

    /**
     *  初始化摄像头
     */
    initWithCamera(elementId, width, height) {
        // 1.检测校验是- 设置默认值
        if (elementId == null || elementId.length == 0) {
            console.warn('请设置相机组件的id');
            return;
        }
        if (width == 0 || width == null) {
            width = 220;
        }
        if (height == 0 || height == null) {
            height = 280;
        }
        this.config.width = width;
        this.config.height = height;
        this.config.dest_width = width;
        this.config.dest_height = height;
        this.config.crop_width = width;
        this.config.crop_height = height;
        this.config.elementId = elementId;

        this.closeCamera();

        // 2. 基本配置
        WebCamJS.set(this.config);

        // 3. 组件配置
        const element = "#" + elementId;
        WebCamJS.attach(element);

        WebCamJS.on("error", function (err) {
            if(err.code == 8){
                HudGlobal.showWarningWithMessage('未找到摄像头,请确认摄像头已经连接');
            }else {
                console.log('摄像头失败加载失败-------',err);
            }
        });
    },

    error() {
        let _this = this;
        return WebCamJS.on("error", function (err) {
            console.log(err);

            // require("@tibi/webcam");
            // _this.initWithCamera(_this.config.elementId, _this.config.width, _this.config.height);
            //
            // setTimeout(function () {
            //     // 如果是在拍照, 重新执行拍照
            //     if (err.stack && err.stack.indexOf("takeCameraSanp") !== -1) {
            //         _this.takeCameraSanp();
            //     }
            // }, 300);
        });
    },

    /**
     * 开始拍照
     */
    takeCameraSanp() {
        return this.takeCameraSnapWithFileName();
    },

    /**
     * 开始拍照并返回
     */
    takeCameraSnapWithFileName(fileName) {
        return new Promise((resolve, reject) => {
            const that = this;

            if (!WebCamJS.container) {
                // that.initWithCamera(that.config.elementId, that.config.width, that.config.height);
                let err = {
                    code: 2,
                    description: "摄像头未初始化"
                }
                reject(err);
            }

            WebCamJS.snap(function (data_base64) {
                // 返回data
                that.__base64ToFile(data_base64, fileName || "file").then(file => {
                    const item = {
                        base64: data_base64,
                        file: file,
                    };
                    if (resolve) {
                        resolve(item);
                    }
                });
            });
        });
    },
    /**
     * 取消拍照
     */
    closeCamera() {
        this.resetCamera();
    },

    /**
     *  复位相机
     */
    resetCamera() {
        try {
            WebCamJS.reset();
        } catch (e) {
            console.log('关闭相机失败');
        }
    },


    //====================================================pravite=====================================================//

    /**
     * base64转文件, 兼容IOS/MAC等, 放弃@dyb881/file
     * @param base64
     * @param fileName
     * @returns {Promise<Blob>}
     * @private
     */
    __base64ToFile(base64, fileName) {
        const data = base64.split(',');
        const type = data[0].slice(5, -7);
        const ext = type.split('/')[1];
        const bstr = atob(data[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let blob = new Blob([u8arr], { type: type });
        blob.lastModifiedDate = new Date();
        blob.name = `${fileName}.${ext}`;
        return Promise.resolve(blob);
    },
    /**
     * 检查摄像头是否能用
     */
    checkCamerUse() {
        return new Promise(resolve => {
            const that = this;
            navigator.getUserMedia({
                audio: true,
                video: {width: that.config.width, height: that.config.height}
            }, function (stream) {
                that.mediaStreamTrack = typeof stream.stop === 'function' ? stream : stream.getTracks()[1];
                if (resolve) {
                    resolve(true);
                }
            }, function (error) {
                console.warn('摄像头启动失败,请检查权限', error);
                if (resolve) {
                    resolve(false);
                }
            })
        }).catch(error => {
            console.warn('摄像头启动失败,请检查权限', error);
        });
    }

}
